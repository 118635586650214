import React from 'react'
import Container from 'react-bootstrap/Container'

/* images */
import quotes from '../../static/testimonials/gfx_quotes2.png'

/* Swiper */
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


export default function Testimonials() {
    return (
        <section id="testimonials">
            <Container>
            
            <h2 className="section-heading white">From our Clients…<img src={quotes} className="quotes" alt="" /></h2>
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    autoPlay
                >
                    <SwiperSlide>
                        <div className="testimonial">
                            <p className="testimonial__review">The administrative staff at Tidy LLC Services is very professional and easy to work with. The on-site employees are hardworking, self starters who take pride in the appearance of this facility.</p>

                            <div className="testimonial__client-info">
                                <h3 className="name">Paul A Tegtmeier</h3>
                                <p className="details">
                                    Administrative Leader,<br/> 
                                    Toyota Motor Sales, U.S.A, Inc<br/> 
                                    Chicago Parts Distribution Center
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Container>
        </section>
    )
}
