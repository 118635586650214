import React from 'react'
import Container from 'react-bootstrap/Container'

/* images */
import industrial from '../../static/services/service_industrial@2x.jpg'
import generalOffice from '../../static/services/service_general_office@2x.jpg'
import sanitization from '../../static/services/service_sanitation@2x.jpg'
import emergency from '../../static/services/service_emergency@2x.jpg'
import windowWashing from '../../static/services/service_window@2x.jpg'
import floorCarpet from '../../static/services/service_floor@2x.jpg'

export default function Services() {
    return (
        <section id="services">
            <Container>
                <div className="service-wrap">
                    <h2 className="section-heading green">Our Services</h2>

                    <div className="service-wrap">

                        <div className="service">
                            <div className="service__img">
                                <img src={industrial} alt="Person in hazmat suit wiping down handrailing" />
                            </div>
                            <div className="service__copy">
                                <h3>Industrial Cleaning</h3>
                                <p>Services performed primarily to remove debris, waste that deter work performance; keeping in mind our own safety and that of our client’s. Trained employees and specialized supplies are used for such sites as we have the utmost consideration for company staff, and equipment maintenance.</p>
                            </div>
                        </div>

                        <div className="service">
                            <div className="service__img">
                                <img src={generalOffice} alt="open layout office" />
                            </div>
                            <div className="service__copy">
                                <h3>General Office Cleaning</h3>
                                <p>Trained professionals complete thorough sanitization and cleanliness of active working areas. Such services include each room within the facility such as kitchen, meeting spaces, bathrooms, carpet, windows, and the like.</p>
                            </div>
                        </div>

                        <div className="service">
                            <div className="service__img">
                                <img src={sanitization} alt="Person in hazmat suit cleaning lockers" />
                            </div>
                            <div className="service__copy">
                                <h3>Sanitization Services</h3>
                                <p>Our efforts to ensure sanitization throughout each of our cleaning services is enhanced by offering additional sanitizing and sterilizing services. These services provide support to our customers to eliminate pathogenic agents that live on surfaces to promote healthy work environments.</p>
                            </div>
                        </div>

                        <div className="service">
                            <div className="service__img">
                                <img src={emergency} alt="Person in hazmat suit steam cleaning floor" />
                            </div>
                            <div className="service__copy">
                                <h3>Emergency Cleaning</h3>
                                <p>We understand that incidents may occur in the field which may create the need for additional services. We provide emergency cleaning services when such incidents occur to maintain a safe work environment for our clientele.</p>
                            </div>
                        </div>

                        <div className="service">
                            <div className="service__img">
                                <img src={windowWashing} alt="person cleaning window" />
                            </div>
                            <div className="service__copy">
                                <h3>Window Washing</h3>
                                <p>Building maintenance not only includes sanitation and maintenance, but the esthetic of a clean work environment as well. Window washing offers the opportunity to keep buildings visibly marketable by utilizing specialized cleaning supplies and trained staff.</p>
                            </div>
                        </div>

                        <div className="service">
                            <div className="service__img">
                                <img src={floorCarpet} alt="Person cleaning warehouse floor" />
                            </div>
                            <div className="service__copy">
                                <h3>Floor and Carpet Cleaning</h3>
                                <p>Quality methods of cleaning and maintenance are used to promote a safe environment keeping the space sanitary if there should be any dirt or debris located on the surfaces.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </Container>
        </section>
    )
}
