import React, { useState } from "react"
import { Link } from 'gatsby'
import Container from 'react-bootstrap/Container'
import freeEstimate from "../../static/header/gfx_btn_freeEst@2x.png"
import { useScrollPosition } from './useScrollPosition'

export default function Hero() {
    const [scroll, setScroll] = useState(0);

    useScrollPosition(function setScrollPosition ({ currentPosition }) {
        setScroll(currentPosition.y);
    });

    return (
        <div className="hero">
            <Container>
                <div className="hero-content">
                    <div className="hero-content__left">
                        <h1>Environmentally Conscious Multi-custodial Services</h1>
                        <p>Tidy LLC provides exemplary janitorial services which create clean and hygienic workplace environments to a wide variety of clientele.</p>
                    </div>
                    <div className="hero-content__right">
                        <p>We offer one-time and ongoing custodial services for the following industries:</p>
                        <ul>
                            <li>&bull; Offices</li>
                            <li>&bull; Industrial Complexes</li>
                            <li>&bull; Construction development</li>
                            <li>&bull; Warehouses</li>
                            <li>&bull; General business locations</li>
                            <li>&bull; Retail Stores</li>
                        </ul>
                    </div>
                </div>
            </Container>
            
            <Link to="#contact-us" className="get-estimate">
                <img src={freeEstimate} alt="Get a free estimate" />
            </Link>

            <Link to="#contact-us" className={`get-estimate-small ${scroll < -960 ? "show" : "hide"}`}>
                <img src={freeEstimate} alt="Get a free estimate" />
            </Link>
        </div>
    )
}
