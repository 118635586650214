import React from 'react'
import Container from 'react-bootstrap/Container'

/* images */
import hacia from '../../static/assc/logo_assc_Hacia@2x.png'
import cmsdc from '../../static/assc/logo_assc_CMSDC@2x.png'

export default function CertificationsMemberships() {
    return (
        <section id="certifications">
            <Container>
                <h2 className="section-heading green">Certifications &amp; Memberships</h2>

                <div className="logo-wrap">
                    <div className="logo">
                        <a href="https://www.haciaworks.org/">
                            <img src={hacia} alt="Hacia" />
                        </a>
                    </div>

                    <div className="logo">
                        <a href="http://www.chicagomsdc.org/">
                            <img src={cmsdc} alt="Chicago Minority Supplier Development Council" />
                        </a>
                    </div>
                </div>
            </Container>
        </section>
    )
}
