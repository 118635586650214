import React from 'react'
import Container from 'react-bootstrap/Container'

/* images */
import walsh from '../../static/clients/logo_client_walsh@2x.png'
import marianos from '../../static/clients/logo_client_marianos@2x.png'
import toyota from '../../static/clients/logo_client_toyota@2x.png'
import turner from '../../static/clients/logo_client_turner@2x.png'
import weyerhaeuser from '../../static/clients/logo_client_Weyerheser@2x.png'

export default function Customers() {
    return (
        <section id="customers">
            <Container>
                <h2 className="section-heading green">Customers</h2>

                <div className="logo-wrap">
                    <div className="logo">
                        <img src={walsh} alt="Walsh" />
                    </div>

                    <div className="logo">
                        <img src={marianos} alt="Marianos" />
                    </div>

                    <div className="logo">
                        <img src={toyota} alt="Toyota" />
                    </div>

                    <div className="logo">
                        <img src={turner} alt="Turner" />
                    </div>

                    <div className="logo">
                        <img src={weyerhaeuser} alt="Weyerhaeuser" />
                    </div>
                </div>
            </Container>
        </section>
    )
}
