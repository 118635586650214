import React from 'react'
import Container from 'react-bootstrap/Container'

/* images */
import warehouse from '../../static/aboutus/aboutUs_1@2x.jpg'
import railing from '../../static/aboutus/aboutUs_2@2x.jpg'

export default function AboutUs() {
    return (
        <section id="about-us">
            <Container>
                <h2 className="section-heading green">About Us</h2>

                <div className="about-wrap">
                    <div className="about">
                        <div className="about__img">
                            <img src={warehouse} alt="tidy employees cleaning items in warehouse" />
                        </div>
                        <div className="about__copy">
                            <h3 className="about__copy__header green">Over 34 Years in the Industry</h3>
                            <p><span className="bold">TIDY, LLC</span> is a multi-custodial service company with more than 34 years of experience in commercial and industrial cleaning services utilizing innovative and environmentally conscious practices. We provide a holistic cleaning service approach to meet all of our projects’ needs and deadlines. By using the most effective cleaning and eco-friendly methods, our bilingual staff is fully trained and available “On-Demand” to meet and exceed expectations.</p>
                        </div>
                    </div>

                    <div className="about">
                        <div className="about__img">
                            <img src={railing} alt="tidy employee sanitizing a handrail" />
                        </div>
                        <div className="about__copy">
                            <h3 className="about__copy__header green">An Award-winning Company</h3>
                            <p><span className="bold">COMPANY PRESIDENT & CEO Roberto Ramirez</span> has won multiple awards and recognitions as Founder, President & CEO of TIDY for his efforts and initiatives as an entrepreneur and philanthropist. Under Roberto’s leadership, TIDY has grown to be a leader in the industry and be among the fastest growing Hispanic companies in the United States. Roberto and TIDY have been honored with the <span className="bold">"Chicago Hispanic Business of the Year"</span> award presented by the Hispanic American Construction Industry Association (HACIA). Roberto has also served in the HACIA Board of Directors.</p>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}
