import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Testimonials from "../components/Testimonials"
import Customers from "../components/Customers"
import AboutUs from "../components/AboutUs"
import CertificationsMemberships from "../components/CertificationsMemberships"

export const query = graphql`
{
  site {
    siteMetadata {
      author
      title
      description
      image
      keywords
      url
    }
  }
}
`

export default function Home({data}) {

  return (
    <div>
      <Helmet htmlAttributes={{lang: 'en'}}>
            
            <title>{data.site.siteMetadata.title}</title>
            <meta name="description" content={data.site.siteMetadata.description} />
            <meta name="keywords" content={data.site.siteMetadata.keywords} />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={data.site.siteMetadata.description} />
            <meta property="og:image" content={data.site.siteMetadata.image} />
            <meta property="og:locale" content="en_US" />
            <meta property="og:url" content={data.site.siteMetadata.url} />
            <link rel="canonical" href={data.site.siteMetadata.url} />
      </Helmet>
      <Layout>
      
        <Hero />
        <Services />
        <Testimonials />
        <Customers />
        <AboutUs />
        <CertificationsMemberships />
      </Layout>
    </div>
  )
}